import { Link } from 'gatsby';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { getBoardItem, getBoardLength } from '../../common/board.loader';
import dateToStr from '../../common/dateToStr';
import ChevronLeftIcon from '../../components/icons/chevron.left';
import ChevronRightIcon from '../../components/icons/chevron.right';
import BoardTitle from '../../components/pages/board/title';
import Main from "../../layout/main";

export default function BoardView (props) {

  const params = new URLSearchParams(props.location.search);
  const idx = Number(params.get('idx'));
  const BoardItem = getBoardItem(idx);
  const BoardLength = getBoardLength();
  const {t} = useTranslation();
  return (
    !BoardItem ? <></> :
    <Main title="게시글" {...props}>
      <BoardViewLayout>
      <BoardTitle />
        <section className="flex-1 w-full h-full flex flex-col border-t-2 border-b-2 border-gray-300">
          <BoardHeader title={BoardItem.title} date={BoardItem.date}/>
          <BoardContent content={BoardItem.content} />
          <div className="w-full flex justify-between py-4 text-gray-500">
            <PreviousButton show={idx > 0}  moveIdx={idx - 1} text={t("posts.pre")}/>
            <NextButton  show={idx < BoardLength-1} moveIdx={idx + 1} text={t("posts.next")}/>
          </div>
        </section>
       <div className="mt-8 flex justify-center">
          <ListButton text={t("posts.list")}/>
        </div>
      </BoardViewLayout>
    </Main>
  )
}
const BoardViewLayout = ({children}) => (
  <article className="flex-1 w-full lg:max-w-7xl h-full mx-auto flex flex-col px-4 md:px-page-x-sm lg:px-page-x mb-[72px]">
    {children}
  </article>
)
const BoardHeader = ({title, date}) => (
  <div className="px-2 py-4 flex justify-between">
    <span className="flex-1 flex items-center truncate text-lg text-gray-080">{title}</span>
    <span className="ml-10 flex items-center text-gray-500 text-sm">{dateToStr(date)}</span>
  </div>
)
const BoardContent = ({content = ""}) => (
  <div className="flex-1 h-full border-y border-gray-300 p-4 whitespace-pre-line break-words" dangerouslySetInnerHTML={{__html : content}}>
  </div>
)
const PreviousButton = ({text="이전글", show=true, moveIdx=0}) => (
    show 
    ?
      <Link to={`/board/view?idx=${moveIdx}`}>
        <button type="button" className={`flex space-x-2 items-center`}>
          <ChevronLeftIcon className="w-4 h-4"/> 
          <span>{text}</span>
        </button>
      </Link>
    : <div className=""/>
)
const NextButton = ({text="다음글", show=true, moveIdx=0}) => (
    show 
    ?
      <Link to={`/board/view?idx=${moveIdx}`}>
        <button type="button" className={`flex space-x-2 items-center`}>
          <span>{text}</span>
          <ChevronRightIcon className="w-4 h-4"/>
        </button>
      </Link>
    : <div className=""/>
)
const ListButton = ({text="목록보기"}) => (
  <Link to="/board/list">
    <button type="button" className="py-2 px-14 border border-main rounded-3xl text-main">{text}</button>
  </Link>
)