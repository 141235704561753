import React from 'react'
import { useTranslation } from 'react-i18next'

const BoardTitle = () => (
  <article className="w-full mt-[124px] mb-[72px]">
    <Title />
  </article>
)
const Title = () => {
  const {t} = useTranslation();
  return (
    <div className="text-[40px] leading-[62px] font-bold text-gray-080 font-metropolis">{t("posts.title")}</div>
  )
}
export default BoardTitle;